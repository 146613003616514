
import {} from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContactUsFormComponent',
  components: {},
  methods: {
    handleClick() {
      console.log('CLICKED')
    },
  },
})
