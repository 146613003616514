
import {} from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FooterComponent',
  components: {},
  methods: {
    scrollToTop() {
      document.getElementById('top')?.scrollIntoView()
    },
  },
})
