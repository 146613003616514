import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/pages/HomePage.vue'
import AboutUsPage from '../views/pages/AboutUsPage.vue'
import ServicesPage from '../views/pages/ServicesPage.vue'
import ContactUsPage from '../views/pages/ContactUsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about-us',
    name: 'About-Us',
    component: AboutUsPage
  }
  ,
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage
  },
  {
    path: '/contact-us',
    name: 'Contact-Us',
    component: ContactUsPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
