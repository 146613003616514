
import {} from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['page'],
  name: 'HeaderComponent',
  components: {},
  data() {
    return {
      showMobileMenu: false,
    }
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
  },
})
