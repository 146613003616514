import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

//Components
import HeaderComponent from './views/components/HeaderComponent.vue';
import FooterComponent from './views/components/FooterComponent.vue';
import ContactUsFormComponent from './views/components/ContactUsFormComponent.vue';
import OurFactsComponent from './views/components/OurFactsComponent.vue';
import SkillsComponent from './views/components/SkillsComponent.vue';

/* Theme variables */
import './theme/variables.css';
import '../public/styles/flex-slider.css'
import '../public/styles/fontawesome.css'
import '../public/styles/lightbox.css'
import '../public/styles/owl.css'
import '../public/styles/templatemo-edu-meeting.css'
import '../public/styles/common.css'

// import "./scripts/custom.js";
// <!-- <script src="../public/scripts/custom.js"></script> -->
//     <!--<script src="../public/scripts/lightbox.js"></script>
//     <script src="../public/scripts/isotope.min.js"></script>
//     <script src="../public/scripts/owl-carousel.js"></script>
//     <script src="../public/scripts/slick-slider.js"></script>
//     <script src="../public/scripts/tabs.js"></script> -->

const app = createApp(App)
  .use(IonicVue)
  .use(router);

  app.component('header-layout', HeaderComponent);
  app.component('footer-layout', FooterComponent);
  app.component('contact-us-layout', ContactUsFormComponent);
  app.component('our-facts-layout', OurFactsComponent);
  app.component('skils-layout', SkillsComponent);

  
router.isReady().then(() => {
  app.mount('#app');
});