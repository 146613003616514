
import {} from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AboutUsPage',
  components: {},
  methods: {
    scrollToView(elementId: string) {
      document.getElementById(elementId)?.scrollIntoView()
    },
  },
})
